/* eslint-disable react/no-unknown-property */
import * as React from 'react'
import { useState } from 'react';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {callHook} from '../../utils/utils';
import { Replace } from '../../utils/utils';
import translate from '../../translate';
var validator = require("email-validator");
import settings from '../../settings.json';

const SOCIALS = ['facebook','twitter','instagram','youtube','linkedin'];

const ICONS = {
  'Your Name': <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16"><path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/></svg>,
  Photo: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-images" viewBox="0 0 16 16"><path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/><path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"/></svg>,
  Contact: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16"><path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/></svg>,
  Email: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-at" viewBox="0 0 16 16"><path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/><path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/></svg>,
  Company: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16"><path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"/><path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z"/></svg>,
  Addr: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16"><path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"/></svg>,
  Website: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16"><path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/><path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/></svg>,
  Social: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16"><path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/></svg>,
  facebook: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16"><path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/></svg>,
  twitter: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16"><path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/></svg>,
  youtube: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16"><path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/></svg>,
  instagram: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16"><path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/></svg>,
  linkedin: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16"><path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/></svg>
};

const defaultValues = {
  vcard: [
    {
      name: 'Your Name',
      items:[{name: "firstName", required:true},{name: "lastName", required:true}]
    }/*,{
      name: 'Photo',
      items:[{name: "photo"}]
    }*/,{
      name: 'Contact',
      items:[{name: "phone", label: ''}]
    },{
      name: 'Email',
      items:[{name: "email", label: ''}]
    },{
      name: 'Company',
      items: [{name: "company"},{name: "job"}],
    },{
      name: 'Addr',
      items:[{name: "street"},{name: "city"},{name: "state"},{name: "zip"},{name: "country"}]
    },{
      name: 'Website',
      items:[{name: "website"}]
    },{
      name: 'Social',
      items: []
    }
  ]
}
const vCardData = [
  "BEGIN:VCARD",
  "VERSION:3.0",
  "N:${lastName};${firstName}",
  "FN:${firstName} ${lastName}",
  //"ORG:${company}",
  //"TITLE:${job}",
  //"ADR:;;${street};${city};${state};${zip};${country}",
  // "TEL;WORK;VOICE:${phone}",
  // "TEL;CELL:${mobile}",
  // "TEL;FAX:${fax}",
  // "EMAIL;WORK;INTERNET:${email}",
  // "URL:${website}",
  // "PHOTO;TYPE=JPEG;VALUE=URL:${photo}",
  // "END:VCARD"
];
const isObject = (value) => value.constructor === Object;

export const QRvCard = ({ gclid, msclkid }) =>  {
  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [isUsageOther, setUsageOther] = useState(false);
  const [hookResponse, setHookResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [usageOption, setUsageOption] = useState();

  const onUsageOtherCheckbox = () => {
	  setUsageOther(!isUsageOther);
    console.log("setUsageOther ",isUsageOther)
	};

  const {
    control,
    register,
    handleSubmit,
    getValues,
    // errors,
    reset,
    setValue,
    formState: { errors, isSubmitted }
  } = useForm({
    defaultValues
  });

  const onShortenClick = () => {
	  setIsEmailVisible(!isEmailVisible);
	};

  const onSubmit = async (data) => {
    console.log('data: ', data);
    const url = settings.n8n_qr_gen
    const mode = "no-cors"
    const usage = {"option1":data.usage1, "option2":data.usage2, "option3":data.usage3, "option4":(isUsageOther ? data.usageOther : false )}
    if (data.usage2) {
      setUsageOption(2)
    } else {
      setUsageOption()
    }
    let qrData = {type: "vCard", email: data.rEmail.trim(), usage: usage, data: "", gclid: gclid, msclkid: msclkid}
    const d = {};
    data.vcard.forEach(el => {
      el.items.forEach(e => {
        const n = e.name;
        d[n] = e[n];
      })
    });
    console.log('dict: ', d);
    const _data = vCardData.map(item => {
      return Replace(item, d);
    });

    // company
    const _company = data.vcard.find(i => i.name === 'Company');
    _company.items.forEach(el => {
      if (el.company) {
        const txt = `ORG:${el.company}`
        _data.push(txt)
      }
      if (el.job) {
        const txt = `TITLE:${el.job}`
        _data.push(txt)
      }
    });

    // address
    const _address = data.vcard.find(i => i.name === 'Addr');
    const a = {
      street: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    };
    const _addr = _address.items.filter(el => {
      const {name} = el;
      return el[name];
    });
    _addr.forEach(el => {
      const {name} = el;
      a[name] = el[name] || '';
    });
    const addrString = `ADR:;;${a.street};${a.city};${a.state};${a.zip};${a.country}`;
    if (_addr.length) {
      _data.push(addrString);
    }

  /*
    let addrString = `ADR:;;`
    let addrIndex = _address.items.findIndex((el) => el.name === "street")
    addrString = `${addrString}${(_address.items[addrIndex].street === undefined) ? `` : _address.items[addrIndex].street};`
    addrIndex = _address.items.findIndex((el) => el.name === "city")
    addrString = `${addrString}${(_address.items[addrIndex].city === undefined) ? `` : _address.items[addrIndex].city};`
    addrIndex = _address.items.findIndex((el) => el.name === "state")
    addrString = `${addrString}${(_address.items[addrIndex].state === undefined) ? `` : _address.items[addrIndex].state};`
    addrIndex = _address.items.findIndex((el) => el.name === "zip")
    addrString = `${addrString}${(_address.items[addrIndex].zip === undefined) ? `` : _address.items[addrIndex].zip};`
    addrIndex = _address.items.findIndex((el) => el.name === "country")
    addrString = `${addrString}${(_address.items[addrIndex].country === undefined) ? `` : _address.items[addrIndex].country}`

    if (addrString != `ADR:;;;;;;`) {
      _data.push(addrString)
    }
*/
    // website
    const _website = data.vcard.find(i => i.name === 'Website');
    _website.items.forEach(el => {
      if (el.website) {
        const txt = `URL:${el.website}`
        _data.push(txt)
      }
    });
    // photo
    /*const _photo = data.vcard.find(i => i.name === 'Photo');
    _photo.items.forEach(el => {
      if (el.photo) {
        const txt = `PHOTO;TYPE=JPEG;VALUE=URL:${el.photo}`
        _data.push(txt)
      }
    });*/
    // todo: field with label remove 'name'?
    // const c = formFields['Contact'];
    const c = data.vcard.find(i => i.name === 'Contact')
    const cl = c.items.length;
    let phoneForQR = null;
    c.items.forEach((item, index) => {
      const {name} = item;
      const label = item.label || 'HOME';
      if (item.phone) {
        if (!phoneForQR) phoneForQR = item.phone;
        // if (label) {
        //   const uid = `item${index}`;
        //   // const txt1 = `${uid}.TEL:${item[name]}`;
        //   const txt1 = `${uid}.TEL:${item.phone}`;
        //   _data.push(txt1)
        //   const txt2 = `${uid}.X-ABLabel:${item.label}`
        //   _data.push(txt2)
        // } else {
          const txt = `TEL;${label}:${item.phone}`;
          _data.push(txt)
        // }
      }
    });

    // const e = formFields['Email'];
    const e = data.vcard.find(i => i.name === 'Email')
    let emailForQR = null;
    e.items.forEach((item, index) => {
      const {name} = item;
      const label = item.label || 'HOME';
      if (item.email) {
        if (!emailForQR) emailForQR = item.email;
        // if (label) {
        //   const uid = `item${index+cl}`;
        //   const txt1 = `${uid}.EMAIL:${item.email}`;
        //   _data.push(txt1)
        //   const txt2 = `${uid}.X-ABLabel:${item.label}`
        //   _data.push(txt2)
        // } else {
          const txt = `EMAIL;${label}:${item.email}`;
          _data.push(txt)
        // }
      }
    });
    const s = data.vcard.find(i => i.name === 'Social')
    s.items.forEach(el => {
      let txt = null;
      if (el.facebook) {
        txt = `X-SOCIALPROFILE;TYPE=facebook:${el.facebook}`
      } else if (el.twitter) {
        txt = `X-SOCIALPROFILE;TYPE=twitter:${el.twitter}`
      } else if (el.instagram) {
        txt = `X-SOCIALPROFILE;TYPE=instagram:${el.instagram}`
      } else if (el.youtube) {
        txt = `X-SOCIALPROFILE;TYPE=youtube:${el.youtube}`
      } else if (el.linkedin) {
        txt = `X-SOCIALPROFILE;TYPE=linkedin:${el.linkedin}`
      }
      if (txt) _data.push(txt);
    })
  
    _data.push('END:VCARD');
    console.log('all data: ', _data);
    qrData.data = _data.join('\r\n');
    //console.log('qrData: ', qrData);

    if (!(d.lastName && d.firstName)) {
      return;
    }

    // **** for passkit
    const person = {
      "surname": d.lastName,
      "forename": d.firstName,
    };

    const text = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${d.lastName};${d.firstName}`,
      `FN:${d.firstName} ${d.lastName}`,
    ];
    if (emailForQR) {
      person.emailAddress = emailForQR.trim();
      text.push(`EMAIL;TYPE=INTERNET:${emailForQR}`);
    }
    if (phoneForQR) {
      person.mobileNumber = phoneForQR;
      text.push(`TEL;CELL:${phoneForQR}`);
    }

    text.push("END:VCARD")

    const qr_pass = {
      "person": person,
      "vcard": text.join("\r\n")
    }
    // console.log('qr_pass: ', qr_pass);
    // ****************
    qrData.qrPass = qr_pass;
    setLoading(true);
    const r = await callHook({url, uid:'yohn-qr-code', params:qrData, mode});
    // const r = { "create-wallet-card-url": "https://yo.hn/create-wallet-card?token=MjAyMy0xMi0yMS92Y2FyZC0wOS00Ni0wMC02NDk" };
    console.log('callHook r: ', r);
    setHookResponse(r);
    setSend(true);
    setLoading(false);
    scroll(0,140);
  }

  if (loading) {
    return (
      <div className="text-center">
        <div class="loader mx-auto"></div>
        <h3 className="mt-4">Your QR code is being generated and will be ready in a moment.</h3>
      </div>
    );
  }

  const EmailSignature = () => (
    <div className="text-center col-12 col-lg-8 p-5 bg-white rounded-4" style={{border:"solid 4px #c5f1df"}}>
      <p className="h3">Need an email signature with QR Code?</p>
      <p className="h4">Grab one below and personalize it if you wish.</p>
      <div className="py-4 d-flex justify-content-center">
        <div className="d-flex text-start p-2 rounded" style={{maxWidth:"512px", boxShadow: "rgba(200,220,255, .75) 0px 4px 16px"}}>
          <img src="https://images.prismic.io/colibri-live/e9010181-8b11-4b05-acf3-1d7d58588841_qr-js-vcf.png" width="140" height="140" alt="" />
          <div className="p-3 pb-2">
            <p style={{marginBottom:"10px", fontSize:"20px",lineHeight:"20px",fontWeight:"700"}}>John Smith</p>
            <p style={{marginBottom:"0px", fontSize:"16px",lineHeight:"16px",fontWeight:"600"}}>Sales Agent at Yohn.io</p>
            <div style={{width:"100%",borderBottom:"1px solid #e0e0e4", margin:"12px 0"}}></div>
            <p style={{marginBottom:"10px", fontSize:"14px", lineHeight:"14px", fontWeight:"400px"}}>Kurze Gasse 10/1 Sindelfingen 71063 Germany</p>
            <p style={{marginBottom:"10px", fontSize:"14px", lineHeight:"14px", fontWeight:"400px", textDecoration:"none"}}><a href="mailto:john.smith@yohn.io" style={{color:"#202830", textDecoration:"none"}}>john.smith@yohn.io</a></p>
            <p style={{marginBottom:"0px", fontSize:"14px", lineHeight:"14px", fontWeight:"400px", textDecoration:"none"}}><a href="mailto:+49123456789" style={{color:"#202830", textDecoration:"none"}}>+49123456789</a></p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <p className="h4 mt-3 mb-0">Standard signature</p>
          <a href="https://yo.hn/email-signature-standard" className="my-2 col btn btn-sm btn-primary text-uppercase">1.99€ <span style={{fontSize:"11px"}}>(one-time fee)</span></a>
          <p className="">based on the template above</p>
        </div>
        <div className="col-12 col-md-6">
          <p className="h4 mt-3 mb-0">Custom signature</p>
          <a href="https://yo.hn/email-signature-custom" className="my-2 col btn btn-sm btn-primary text-uppercase">9.99€ <span style={{fontSize:"11px"}}>(one-time fee)</span></a>
          <p className="">alternative templates available, custom colors, company logo, etc.</p>
        </div>
      </div>
      <div className="col-12 col-xl-9 mx-auto">
        <p className="h5 mt-4">Need corporate email signatures with individual QR codes for your whole team? <a href="https://www.yohn.io/contact-us/" >Request a quote here</a>.</p>
      </div>
    </div>
  )

  if (isSend) {
    const createWalletCardUrl = hookResponse['create-wallet-card-url'];
    return (
      <div id="try">
		    <div className={"container"}>
          {/*mobile*/}
          <div className="d-block d-lg-none">
            <div className="row gx-3 gy-4">
              <div className="text-center col-12 col-lg-6 mx-auto d-flex flex-column justify-content-center">
                {!isError && (
                <>
                  <h3>Thank You!</h3>
                  <h3>We have sent the QR code to your e-mail address.</h3>
                </>
                )}
                {isError && (
                <>
                  <p className="h3">An error has occurred</p>
                  <p>Please try again later, server is not available now.</p>
                </>
                )}
                <div className="col-auto mt-4">
                  <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{isError ? "Try again" : "Generate another"}</button>
                </div>
              </div>
              {}
              {/*email signature*/ (usageOption == undefined) &&
              <div className="text-center col-12 col-lg-6 p-4 mx-auto bg-white rounded-4" style={{border:"solid 4px #c5f1df"}}>
                <p className="h4">Add Your vCard QR to Your Apple / Google Wallet</p>
                <div className="mx-auto" style={{maxWidth:"320px"}}>
                  <img src="https://images.prismic.io/colibri-live/5fd70537-a31f-4636-82c5-a95da461ae16_wallet-card-email-2.png" className="img-fluid" alt="Business Card Wallet"/>
                </div>
                <ul className="mt-4" style={{width:"fit-content",margin:"auto",textAlign:"left"}}>
                  <li>A business card that is always with you</li>
                  <li>Add your logo/brand</li>
                  <li>Try free for 7 days</li>
                </ul>
                <div className="mt-4">
                  <a href={createWalletCardUrl} target="_blank" rel='noreferrer'><img src="https://images.prismic.io/colibri-live/bc62e3a7-cf93-428a-86e1-85e4834a0646_US-UK_Add_to_Apple_Wallet.png" style={{margin:"4px 8px"}} alt="Apple wallet"/></a>
                  <a href={createWalletCardUrl} target="_blank" rel='noreferrer'><img src="https://images.prismic.io/colibri-live/763224d2-5f17-44cd-a9b4-ed5ae4b8aa88_enUS_add_to_google_wallet_add-wallet-badge.png" style={{margin:"4px 8px"}} alt="Google wallet"/></a>
                </div>
              </div>
              }
              {/*email signature*/ (usageOption == 2) &&
                <EmailSignature/>
              }
            </div>
          </div>
          {/*desktop*/}
          <div className="d-none d-lg-block">
            <div className="row gx-3 gy-4">
              <div className="text-center col-4 mx-auto d-flex flex-column justify-content-center px-4">
                {!isError && (
                <>
                  <h3>Thank You!</h3>
                  <h4>We have sent the QR code to your e-mail address.</h4>
                </>
                )}
                {isError && (
                <>
                  <p className="h3">An error has occurred</p>
                  <p>Please try again later, server is not available now.</p>
                </>
                )}
                <div className="col-auto mt-4">
                  <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{isError ? "Try again" : "Generate another"}</button>
                </div>
              </div>
              {/*premium QR*/ (usageOption == undefined) &&
              <div className="text-center col-8 p-4 mx-auto bg-white rounded-4" style={{border:"solid 4px #c5f1df"}}>
                <p className="h3">Try our premium vCard QR codes</p>
                <p className="h4">Scan each of the QR codes below to see the difference</p>
                <div className="row mt-4">
                  <div className="col-6">
                    <div className="mx-auto" style={{maxWidth:"240px",height:"240px"}}>
                      <img src="https://images.prismic.io/colibri-live/c431dcb9-b278-41c0-ba71-4769cf654ff1_john-smith-offline-qr-1.png" className="img-fluid" alt="Regular vCard QR code"/>
                    </div>
                    <p className="h4 mt-3">Regular vCard QR code</p>
                    <ul className="" style={{width:"fit-content",margin:"auto",textAlign:"left"}}>
                      <li>complex</li>
                      <li>not good for printing</li>
                      <li>no scan analytics</li>
                      <li>does not support photos</li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <div className="mx-auto d-flex flex-column justify-content-center" style={{maxWidth:"240px",height:"240px"}}>
                      <img src="https://images.prismic.io/colibri-live/39559e6d-c6b5-40df-9f3e-2f360730fa73_qr-js-vcf.png" className="img-fluid" alt="Regular vCard QR code"/>
                    </div>
                    <p className="h4 mt-3">PREMIUM vCard QR code</p>
                    <ul className="" style={{width:"fit-content",margin:"auto",textAlign:"left"}}>
                      <li>simple & clean</li>
                      <li>perfect for printing</li>
                      <li>see how many times it got scanned</li>
                      <li>supports profile photos</li>
                    </ul>
                  </div>
                </div>
                <p className="h5 mt-4">Learn more about the benefits of our premium vCard QR codes</p>
                <div className="mt-3 row justify-content-center">
                  <div className="col-auto">
                    <a href="https://yo.hn/demo-vcard-qr-success-screen" className="col btn btn-sm text-uppercase btn-primary">Get a Live Demo!</a>
                  </div>
                  <div className="col-auto">
                    <a href="https://yo.hn/signup-vcard-qr-success-screen" className="col btn btn-sm text-uppercase btn-primary">Start Free Trial!</a>
                  </div>
                </div>
              </div>
              }
              {/*email signature*/ (usageOption == 2) &&
                <EmailSignature/>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }


  const addLine = ({groupName, field}) => {
    console.log(`"${groupName}" add: `, field);
    const group = formFields[groupName];
    const count = group.items.length;
    const {name} = field;
    group.items.push({name});
    setUpdate(!isUpdate);
  };

  const deleteLine = ({groupName, field, lineNum}) => {
    console.log(`"${groupName}" add: `, field);
    const group = formFields[groupName];
    const count = group.items.length;
    const {name} = field;
    // group.items.splice({name});
    group.items.splice(lineNum, 1);
    setUpdate(!isUpdate);
  };

  return (
    <div className="col-12 mx-auto row align-items-center">
      <form id="vcard" className="text-center col" onSubmit={handleSubmit(onSubmit)}>
        {/* <h1>Try it yourself!</h1> */}
        <RenderForm
          {...{ control, register, defaultValues, getValues, setValue, errors }}
        />
        
        <div className="container border rounded-4 text-start p-4 col-12 col-md-9 col-lg-7 mx-auto">
          <p><b>Optional</b>: Please let us know how you plan to utilize the VCF QR code generated here. Your feedback will contribute to further development of Yohn.io. Thank you!</p>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              {...register('usage1', {required: false})}
            />
            <label className="form-check-label" for="encryption-wpa">Printing it on a paper business card</label>
          </div>
          <div className="form-check mt-2">
            <input
              className="form-check-input"
              type="checkbox"
              {...register('usage2', {required: false})}
            />
            <label className="form-check-label" for="encryption-wpa">Including it in your email signature</label>
          </div>
          <div className="form-check mt-2">
            <input
              className="form-check-input"
              type="checkbox"
              {...register('usage3', {required: false})}
            />
            <label className="form-check-label" for="encryption-wpa">Incorporating it into a presentation</label>
          </div>
          <div className="form-check mt-2">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={onUsageOtherCheckbox}
            />
            <label className="form-check-label" for="encryption-wpa">Other</label>
          </div>
          {isUsageOther &&
            <textarea
              className={'form-control mt-2'}
              rows="3"
              maxLength="2000"
              {...register('usageOther', {required: false})}
              // aria-invalid={errors.mail ? "true" : "false"}
            />
          }
        </div>
        <div className={"mt-5 col-12 col-md-9 col-lg-7 mx-auto" + " " + (!isEmailVisible ? "d-none" : "")}>
          <input
            className={'form-control' + " " + (errors.rEmail && "is-invalid")}
            placeholder="Your email address"
            {...register('rEmail', {required: true, validate: (input) => validator.validate(input)})}
            // aria-invalid={errors.mail ? "true" : "false"}
          />
          <div className="invalid-feedback" wfd-invisible="true">{'Invalid email address'}</div>
          <div className="text-center mt-3 fs-0875">Type in or paste your email address here and we will send your QR-code to you right away.</div>
        </div>
        <div className={"d-flex justify-content-center col-12 col-md-9 col-lg-7 mx-auto mt-4" + " " + (!isEmailVisible ? "d-none" : "")}>
          <label className="form-check-label" htmlFor="flexCheckChecked"><input className="form-check-input me-2" type="checkbox" value="" id="flexCheckChecked" required/><span className="fs-0875">I agree to the <a href="/terms/">Terms of Service</a>, <a href="/privacy-policy/">Privacy Policy</a> and Use of Cookies.</span></label>
        </div>
        <input type="submit" value="Get QR code" className={"mt-4 btn btn-sm btn-primary text-uppercase" + " " + (!isEmailVisible && "d-none")}/>
      </form>


      <div className={"text-center col-12 mt-4" + " " + (isEmailVisible ? "d-none" : "")}>
        <button onClick={onShortenClick} className={"btn btn-sm btn-primary align-center text-uppercase"}>Get QR code</button>
      </div>
    </div>
  );
}

const RenderField = ({field, nestIndex, lineNum=0, control, name}) => {
  const _name = name || field.name;
  const title = translate(`qr_field_${_name}`);

  return (
    <Controller
      control={control}
      name={`vcard.${nestIndex}.items.${lineNum}.${_name}`}
      render={(props) => {
        return (
          <div className={"position-relative d-flex" + " " + (name == "label" ? "col col-md-3" : "col-md-6")}>
            {(props.field.value) &&
              <div className="input-label">{title}</div>
            }
            <input
              placeholder={title}
              className={"form-control"}
              required={field.required}
              {...props.field}
            />
            <div className="invalid-feedback" wfd-invisible="true">{'Required!'}</div>
          </div>
        )
      }}
    />
  );
}

const RenderCombobox = ({field, nestIndex, lineNum=0, control, name}) => {
  const _name = name || field.name;
  const title = translate(`qr_field_${_name}`);
  let options = [];
  if (field.name === 'phone') {
    options = ['HOME', 'WORK', 'CELL'];
  }
  if (field.name === 'email') {
    options = ['HOME','WORK'];
  }

  return (
    <Controller
      control={control}
      name={`vcard.${nestIndex}.items.${lineNum}.${_name}`}
      render={(props) => {
        return (
          <div className={"position-relative d-flex" + " " + (name == "label" ? "col col-md-3" : "col-md-6")}>
            {(props.field.value) &&
              <div className="input-label">{title}</div>
            }
            <select className={"form-control"} {...props.field} >
              {options.map((name, i) => (
                <option key={i} value={name}>{name}</option>
              ))}
            </select>
            <div className="invalid-feedback" wfd-invisible="true">{'Required!'}</div>
          </div>
        )
      }}
    />
  );
}


const RenderFields = ({ groupName, nestIndex, control, register, setValue, getValues }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `vcard.${nestIndex}.items`
  });
  const vc = getValues().vcard;
  const group = vc.find(i => i.name === groupName);
  // console.log('group: ', group);
  const count = group.items.length - 1;
  const withLabel = ['Contact','Email'];

  if (groupName === 'Social') {
    const s = fields.map(i => i.name);
    const addSocials = SOCIALS.filter(i => !s.includes(i));

    return (
      <div className="col">
        <div className="d-flex mb-3 if-empty">
        {addSocials.map((name, i) => (
          <button
            key={i}
            className="btn btn-primary btn-sm p-1 ps-2 me-2"
            onClick={() => {
              // console.log('social name: ', name);
              append({'name': name}) ;
            }
          }>
            {ICONS[name]}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16"><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>
          </button>
        ))}
        </div>
        {fields.map((field, lineNum) => (
            <div className={"row g-3" + " " + ((lineNum > 0) ? "mt-2" : "")} key={field.id} >
            <RenderField {...{control, field, nestIndex,lineNum}} />
            <div className="col-auto d-flex flex-column justify-content-center">
              <button
                className="btn btn-light btn-sm my-auto"
                style={{padding:"1px 3px 3px 3px"}}
                onClick={() => remove(lineNum) }>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/></svg>
              </button>
            </div>
          </div>
        )
        )}
     </div>
    )
  }

  return (
    fields.map((field, lineNum) => {
      // console.log('field: ', field, count, lineNum)
      const isLabel = withLabel.includes(groupName);
      if (isLabel) {
        return (
          <div className={"row g-3" + " " + ((lineNum > 0) ? "mt-2" : "")} key={field.id} >
            <RenderField name={field.name} {...{control, field, nestIndex,lineNum}} />
            <RenderCombobox name={'label'} {...{control, field, nestIndex,lineNum}} />
            <div className="col-auto d-flex flex-column justify-content-center">
                {(count === lineNum) ?
                  <button
                    className="btn btn-primary btn-sm p-0 my-auto"
                    onClick={() => append({'name': field.name, 'label': '' }) }
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16"><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>
                  </button>
                :
                  <button
                    className="btn btn-light btn-sm my-auto"
                    style={{padding:"1px 3px 3px 3px"}}
                    onClick={() => remove(lineNum) }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/></svg>
                  </button>
                }
              </div>
          </div>
        )
      }
      return (
        <RenderField key={field.id} {...{control, field, nestIndex,lineNum}} />
      );
    })
  );
}

const RenderForm = ({ control, register, setValue, getValues }) => {
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: "vcard"
  });
  return (
    fields.map((item, index) => {
      // console.log('item: ', item);
      return (
        <div key={item.id} className="row mb-4 justify-content-center">
          <div className="col-auto">
            {/*<p>{title}</p>*/}
            <div className="mt-1">{ICONS[item.name]}</div>
          </div>
          <div className="col">
            <div className={(item.name != 'Email' && item.name != 'Contact') ? "row g-3" : ""}>
              <RenderFields groupName={item.name} nestIndex={index} {...{ control, register, setValue, getValues }} />
            </div>
          </div>
        </div>
      );
    })
  );
}